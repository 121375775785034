import React, { useState, useContext, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../datepicker.css';
import { BsCalendar } from 'react-icons/bs';
import { RoomContext } from '../context/RoomContext';

const CheckOut = () => {
  const { startDate, setMinDate, minDate, endDate, setEndDate } = useContext(RoomContext);

  const [showAlert, setShowAlert] = useState(false);

  const handleChange = (date) => {
    setEndDate(date);
  };

  useEffect(() => {
    if (startDate && endDate) {
      const diffInDays = Math.round((endDate - startDate) / (1000 * 60 * 60 * 24));
      setShowAlert(diffInDays < 4);

      if (diffInDays < 4) {
        setEndDate(null); // Set endDate as null when diffInDays is less than 2
      }
    }
  }, [startDate, endDate]);
  useEffect(() => {
    if (startDate) {
      setMinDate(startDate);
    }
  }, [startDate, setMinDate]);

  return (
    <div className='relative flex items-center justify-end h-12 lg:h-16'>
       
      <div className='absolute z-10 pr-8'>
        
        <div className='flex items-center'>
          <BsCalendar className='text-accent text-base' />
        </div>
       
      </div>
      <label>
      {showAlert && (
      <div className=' text-red text-center bg-white lg:text-[13px] lg:w-[100px] lg:h-16  lg:border-l-[0.5px] border-r-[0.5px] border-gray-light'>
        The minimum length of stay is four nights
      </div>
    )}
      </label>
      <DatePicker
        className='font-secondary w-full h-full'
        selected={endDate}
        placeholderText='Check out'
        onChange={handleChange}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        minDate={minDate}
      />
     
    </div>
   
  );
};

export default CheckOut;