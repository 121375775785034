import React, { useContext, useState } from 'react';
import { RoomContext } from '../context/RoomContext';
import { FaPlus } from 'react-icons/fa';
import { FaMinus } from 'react-icons/fa';

const KidsDropdown = () => {
  const { kids, setKids, setNumKidsUnder2 } = useContext(RoomContext);
  const [kidAges, setKidAges] = useState([]);
  const [numKidsUnder2] = useState(0);

  const lis = [
    { name: '0' },
    { name: '1' },
    { name: '2' },
    { name: '3' },
    { name: '4' },
    { name: '5' },
    { name: '6' },
    { name: '7' },
  ];
  const handleIncrement = (e) => {
    const numKids = kidAges.length + 1;
    setKids(kids + 1);

    const updatedKidAges = [...kidAges, { isOver2: null }];
    setKidAges(updatedKidAges);
    e.preventDefault()
  };

  const handleDecrement = (e) => {
    if (kidAges.length > 0) {
      const numKids = kidAges.length - 1;
      setKids(kids - 1);

      const updatedKidAges = kidAges.slice(0, -1);
      setKidAges(updatedKidAges);
    }
    e.preventDefault()
  };

  const handleIsChildOver2Change = (index, value) => {
    const updatedKidAges = [...kidAges];
    updatedKidAges[index].isOver2 = value;
    setKidAges(updatedKidAges);

    const numUnder2 = updatedKidAges.filter((kid) => kid.isOver2 === true).length;
    setNumKidsUnder2(numUnder2);
  };

  return (
    <>
      <div className="w-full h-12 lg:h-16 bg-white flex items-center justify-between">
        <button
          className="text-gray-500 hover:text-gray-700 focus:outline-none"
          onClick={handleDecrement}
        >
          <FaMinus className="w-3 h-3 ml-2 text-straw" />
        </button>
        <span className="font-secondary text-lg">{kids} {kids === 1 ? 'Kid' : 'Kids'}</span>
        <button
          className="text-gray-500 hover:text-gray-700 focus:outline-none"
          onClick={handleIncrement}
        >
          <FaPlus className="w-3 h-3 mr-2 text-straw" />
        </button>
      </div>
      {kids !== 0 && (
        <div className="mt-4 bg-straw/50">
          {kidAges.map((kid, index) => (
            <div key={index} className="flex items-center mb-2">
              <label className="font-secondary mr-2 ml-1">
                Kid {index + 1} is under 2 years old:
              </label>
              <div className="relative flex items-center justify-end h-full">
                <label className="mr-2">
                  <input
                    type="radio"
                    name={`kid${index + 1}`}
                    value="yes"
                    checked={kid.isOver2 === true}
                    onChange={() => handleIsChildOver2Change(index, true)}
                  />
                  Yes
                </label>
                <label>
                  <input
                    type="radio"
                    name={`kid${index + 1}`}
                    value="no"
                    checked={kid.isOver2 === false}
                    onChange={() => handleIsChildOver2Change(index, false)}
                  />
                  No
                </label>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default KidsDropdown;
