import React from 'react';
//link
import {Link} from 'react-router-dom';
//icons
import {BsArrowsFullscreen, BsPeople} from 'react-icons/bs';

const Activity = ({ activity }) => {
    //destructure activity
    const {id, name, image,  description } = activity;
    return (
     <div className='bg-white shadow-2xl min-h-[500px] group'>
      {/* {img} */}
      <div className='overflow-hidden'>
        <img 
        className='group-hover:scale-110 transition-all duration-300 w-full h-[400px] object-cover' 
        src={image} 
        alt='' 
        />
      </div>
      
      {/* details */}
      <div className='bg-white shadow-lg max-w-[300px] mx-auto h-[60px] -translate-y-1/2 flex justify-center items-center uppercase font-tertiary tracking-[1px] font-semibold text-base text-[18px]'>
        <div className='w-[80%]'>
          {/* name */}
            <div className='text-center gap-x-1'>
              {name}
            </div>
        </div>
      </div>
      {/* name & description*/}
      <div className='text-center'>
        <p className='max-w-[300px] mx-auto py-2 lg:mb-6 font-secondary'>
          {description}
        </p>
      </div>
      </div>
    );
  };
  
  export default Activity;
  