import React from 'react';
//components
import Rooms from '../components/Rooms';
import HeroSlider from '../components/HeroSlider';
import ContactForm from '../components/ContactForm';
import { Helmet } from 'react-helmet';



const Home = () => {
  return (
  <>
      <Helmet>
        <title>Home - Peniche Rooftop</title>
      </Helmet>
  <HeroSlider />
  {/* <div className='container mx-auto relative'>
    <div className='bg-accent/20 mt-4 p-4 shadow-xl lg:absolute lg:left-0 lg:right-0 lg:p-0 lg:z-30 lg:-top-12'>
      <BookForm />
    </div>
    
  </div> */}
  
  
  <div>
  <Rooms />
  <ContactForm/>
  </div>
  </>
  );
};

export default Home;
