import React, {useContext, useState} from 'react';
import {useParams} from 'react-router-dom';
//components
import AdultsDropdown from '../components/AdultsDropdown';
import KidsDropdown from '../components/KidsDropdown';
import AvailabilityModal from '../components/AvailabilityModal';
import { Helmet } from 'react-helmet';

//dates
// import {endDate} from '../components/CheckOut';
// import {startDate} from '../components/CheckIn';

//people
// import {adults} from '../components/AdultsDropdown';
// import {kids} from '../components/KidsDropdown';

import CheckOut from '../components/CheckOut';
import CheckIn from '../components/CheckIn';
//scroll top component
import ScrollToTop from '../components/ScrollToTop';

//context
import { RoomContext } from '../context/RoomContext';
//icons
import { FaCheck } from 'react-icons/fa';
import RoomGallery from '../components/RoomGallery';


const RoomDetails = () => {
  const { images, rooms, updateBookedDates, startDate, endDate, adults, kids, numKidsUnder2 } = useContext(RoomContext);
  const {id} = useParams();
  const [showModal, setShowModal] = useState(false);
 

  const handleCheckAvailability = (e) => {
    setShowModal(true);
    e.preventDefault();
  };

  const handleReservation = (roomId, newBookedDates) => {
    updateBookedDates(roomId, newBookedDates);
  };

const room = rooms.find(room => {
  return room.id === Number(id);
});

const {name, description, facilities, imageLg } = room;
  return <section>
     <Helmet>
        <title>Apartment details - Peniche Rooftop</title>
        <meta name="description" content="Peniche Rooftop - villa in Portugal. Details about the accomodation." />
      </Helmet>
    <ScrollToTop />
    {/* banner */}
    <div className='bg-cover bg-center h-[560px] relative flex justify-center items-center' style={{backgroundImage: `url(${imageLg})`}}>
  
      {/* overlay */}
      <div className='absolute w-full h-full bg-black/70'></div>
      {/* title */}
      <h1 className='text-6xl uppercase text-white z-20 font-primary text-center mt-24'>{name} Details
      </h1>
      
    </div>
    <div className='container mx-auto'>
      <div className='flex flex-col h-full py-10 items-center justify-center'>
        {/* left */}
        <div className='w-full h-full  px-3 font-secondary'>
         {/* <h2 className='h2 text-3xl mb-3 font-primary'>{name}</h2> */}
         <div className='mb-8'>{description}</div>
         <RoomGallery images = {images}/>
         {/* <img src={imageLg} alt=''/>
         <button 
         className='bg-straw shadow-lg max-w-[300px] mx-auto px-12 h-[60px] -translate-y-1/2 flex justify-center items-center uppercase font-tertiary tracking-[1px] font-semibold text-white  hover:bg-straw-800'>Gallery</button> */}
         {/* facilities */}
         <div className='mt-12'>
          <h3 className='h3 mb-3 text-2xl font-primary'>Facilities</h3>
        
          {/* grid */}
          <div className='grid grid-cols-3 gap-4 mb-12'>
            {facilities.map((item, index) => {
              //desctructure item
              const {name, icon} = item;
              return <div 
              className='flex items-center gap-2 flex-1'
              key={index}>
                <div className='text-2xl text-accent'>{icon}</div>
                <div className='font-secondary'>{name}</div>
                </div>;
            })}
          </div>
         </div>
        </div>
        {/* right */}
        
        <div className='flex flex-col mt-[50px] items-center'>
          {/* reservation */}
          <form >
          <div className=' py-10 px-6 bg-accent/30'>
            <div className='flex flex-col h-full space-y-4 mb-4'>
            <ul className='flex flex-col gap-y-4 font-secondary'>
              <li className='flex items-center gap-x-4'>
                <FaCheck className='text-accent'/>
                October - April <p className='font-semibold'>250 EUR/night</p>
              </li>
              <li className='flex items-center gap-x-4'>
                <FaCheck className='text-accent'/>
                May - September <p className='font-semibold'>350 EUR/night</p>
              </li>
              <li className='flex items-center gap-x-4'>
                <FaCheck className='text-accent'/>
               <div><p>Easter & Christmas & New Years Eve <br/>(28.12-3.01)</p><p className='font-semibold'>500 EUR/night</p></div>
              </li>
            </ul>
              <h3 className='font-secondary'>Fill in the fields below and submit a request for availability</h3>
              <div className='h-[60px]'>
                <CheckIn />
              </div>
              <div className='h-[60px]'>
                <CheckOut />
              </div>
              <div className='h-[60px]'>
                <AdultsDropdown />
              </div>
              <div className='h-full'>
                <KidsDropdown />
              </div>
            </div>
            <button 
            className='font-tertiary font-semibold uppercase bg-straw hover:bg-straw-800 text-primary text-sm py-2 px-4 mx-auto block w-full h-[60px] mt-8 tracking-[2px]'
            onClick={handleCheckAvailability}>check availability
            </button>
          </div>
          </form>
          {/* rules */}
          <div>
            <h3 className='h3 mb-3 lg:mt-20 sm: mt-12 font-primary text-2xl'>Rules</h3>
            <p className='mb-6 font-secondary'>
            </p>
            <ul className='flex flex-col gap-y-4 font-secondary'>
              <li className='flex items-center gap-x-4'>
                <FaCheck className='text-accent'/>
                Check-in: from 4:00 PM
              </li>
              <li className='flex items-center gap-x-4'>
                <FaCheck className='text-accent'/>
                Check-out: until 11:00 AM 
              </li>
              <li className='flex items-center gap-x-4'>
                <FaCheck className='text-accent'/>
                Smoking, vaping, e-cigarettes not allowed inside the villa
              </li>
              <li className='flex items-center gap-x-4'>
                <FaCheck className='text-accent'/>
                No pets allowed
              </li>
            </ul>
          </div>
        </div>
      </div>
      
    </div>
     {/* Render the AvailabilityModal component conditionally */}
     {showModal && (
        <AvailabilityModal
          roomName={name}
          dates={`${startDate} - ${endDate}`}
          numOfPeople={`${adults} ${adults === 1 ? 'adult' : 'adults'} and ${kids} ${kids === 1 ? 'kid' : 'kids'}`}
          kidsUnderTwo={`${numKidsUnder2}`}
          handleReservation={handleReservation}
          onClose = {() => setShowModal(false)}
        />
      )}
    </section>;
};

export default RoomDetails;
