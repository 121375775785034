import React, { useState } from 'react';
import { FaCheck } from 'react-icons/fa';
import { MdOutlineClose } from "react-icons/md";
import Rooms from "../components/Rooms";
import { FaEnvelope } from 'react-icons/fa';

const ContactForm = () => {
    const [formData, setFormData] = useState({
        email: '',
        repeatedEmail: '',
      });
      const [emailMatch, setEmailMatch] = useState(true);
      const [isChecked, setIsChecked] = useState(false);
      const [showFullText, setShowFullText] = useState(false);
    
      const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
        if (name === 'email') {
          if (value === formData.repeatedEmail && value !== '') {
            setEmailMatch(true);
          } else {
            setEmailMatch(false);
          }
        } else if (name === 'repeatedEmail') {
          if (value === formData.email && value !== '') {
            setEmailMatch(true);
          } else {
            setEmailMatch(false);
          }
        }
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
       
        setFormData({
       
          email: '',
          repeatedEmail: '',
          
        });
      };
      const [copied, setCopied] = useState(false);
      const [showTooltip, setShowTooltip] = useState(false);
      
      const handleCopyEmail = () => {
        const emailText = 'penicherooftop@gmail.com';
        navigator.clipboard
          .writeText(emailText)
          .then(() => {
            setCopied(true);
            setShowTooltip(false);
            setTimeout(() => {
              setCopied(false);
            }, 2000);
          })
          .catch((error) => {
            console.error('Unable to copy email address:', error);
          });
      };
      const handleMouseEnter = () => {
        setShowTooltip(true);
      };
    
      const handleMouseLeave = () => {
        setShowTooltip(false);
      };
      const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
      };
      const toggleFullText = () => {
        setShowFullText(!showFullText);
      };
    

    
  return (
    <div className='container mx-auto lg:px-0'>
    <div className="font-secondary mb-16 mt-8 bg-white  mx-auto shadow-2xl" >
    <h3 className='font-primary flex justify-center uppercase text-[30px] mb-2 mt-4' style={{ zIndex: 1 }}>
       Contact us
      </h3>


    <div className='grid  place-items-center bg-white  mt-16  shadow-lg  lg:w-[600px] sm: w-[320px] mx-auto h-[125px] -translate-y-1/2  text-base'>
    <p className='grid  place-items-center ml-2 mt-1 font-secondary'>
    If you have any questions, please feel free to contact us. 
    </p>

    <p className='grid  place-items-center ml-2 font-secondary'>
    You can reach out to us through this form or write directly to the email address: 
    </p> 

      <p className= "flex flex-row relative cursor-pointer grid  place-items-center hover:text-gray font-semibold">
     <span  onClick={handleCopyEmail}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}> penicherooftop@gmail.com
    </span>
     {showTooltip && (
              <span 
              className='absolute -top-8 left-0 text-xs bg-black text-white py-1 px-2 rounded opacity-100 transition-opacity duration-300'
              >
                Click to copy e-mail
              </span>
            )}
            {copied && (
              <span className='absolute -top-8 left-0 text-xs bg-black text-white py-1 px-2 rounded opacity-100 transition-opacity duration-300'>
                Email copied!
              </span>
            )}
    </p>
    
    </div>


   <form action="https://formsubmit.co/penicherooftop@gmail.com" method="POST" className='grid  place-items-center'>

    <input type="hidden" name="_subject" value="Message from the contact form"/> 
    <input type="hidden" name="_cc" value="franciscomacedo84@gmail.com"></input>
    <input type="hidden" name="_template" value="table"></input>
    <input 
    type="hidden" 
    name="_autoresponse" 
    value="Hi! 
           Your message has been sent succesfully.
           We will reply to you soon. 
           Best Regards, Peniche Rooftop Villa. "
      />

    <input
        type="text"
        name="message title"
        placeholder="Message title"
        className="w-72 px-2 py-1 border border-gray-light rounded "
        required
      />
    <input
        type="text"
        name="name"
        placeholder="Name"
        className="w-72 px-2 py-1 border border-gray-light rounded  sm: mt-4 "
        required
      />
     
    <input
        type="email"
        name="email"
        placeholder="E-mail"
        value={formData.email}
        onChange={handleInputChange}
        className="w-72 px-2 py-1 border border-gray-light rounded mt-4"
        required
      />
     <div className='flex flex-row'>
    <input
        type="email"
        name="repeatedEmail"
        placeholder="Repeat your e-mail"
        value={formData.repeatedEmail}
        onChange={handleInputChange}
        className={`w-72 px-2 py-1 border ${
    emailMatch  ? 'border-gray-light' : 'border-red'
  } ${
    emailMatch && formData.repeatedEmail !== '' ? 'ml-3' : 'ml-0'
  } rounded mt-4 mb-4`}
        required
      />
       {emailMatch && formData.repeatedEmail !== '' && (
  <FaCheck className="text-green mt-8 ml-2" />
)}</div>
      
       {!emailMatch && (
        <p className="text-red text-sm">The repeated email is not the same as above.</p>
      )}
      <textarea
        name="message"
        type="text"
        value={formData.remarks}
        placeholder="Message"
        className="lg:w-[600px] sm: w-[300px] pxresize-y h-[100px] px-2 py-1 border border-gray-light rounded mt-4"
        rows="1"
        required
      />
        <div className=' mt-2 lg:ml-12 lg:mr-8  sm: ml-3 sm: mr-2'>
        
        <input 
        className='mr-1'
        type="checkbox"
        checked={isChecked}
        onChange={handleCheckboxChange}
        required
        />
        <label className='text-[12px]'>
          {showFullText ? (
          <>
            The administrator of provided personal data is Peniche Rooftop Villa. By submitting this form, I consent to the processing of my personal data for the purpose of responding to my inquiry. I am aware that my data will be protected in accordance with applicable laws on the protection of personal data.
          </>
        ) : (
          <>
            The administrator of provided personal data is Peniche Rooftop Villa. By submitting this form, I consent to the processing of my personal data for the purpose of responding to my inquiry.{' '}
            <button
              className="text-blue-500 underline"
              onClick={toggleFullText}
            >
              Show more
            </button>
          </>
        )}
       </label>
       </div>
       <button
            type='submit'
            className='mt-2 bg-gray hover:bg-gray-dark text-white font-bold py-2 px-4 rounded mb-4'
            disabled={!emailMatch} 
          >
        Submit
      </button>
    </form>
  </div>
  </div>
  )
}

export default ContactForm