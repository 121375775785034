// rooms images
import Room1Img from './assets/rooms/1.jpg';
import Room1ImgLg from './assets/rooms/1-lg.jpg';

// activities images
import yogaImg from './assets/activities/yoga.jpg';
import poolImg from './assets/activities/pool.jpg';
import surfImg from './assets/activities/surf.jpg';
import hikeImg from './assets/activities/hiking.jpg';
// location images
import viewImg from './assets/location/view.jpg'
import coastImg from './assets/location/surf.jpg'
import villageImg from './assets/location/village.jpg'
import ceramicImg from './assets/location/ceramic.jpg'
//room images
import House1 from './assets/house/v1.jpg';
import House2 from './assets/house/v2.jpg';
import House3 from './assets/house/v3.jpg';
import House4 from './assets/house/v4.jpg';
import House5 from './assets/house/v5.jpg';
import House6 from './assets/house/v6.jpg';
import House7 from './assets/house/v7.jpg';
import House8 from './assets/house/v8.jpg';
import House9 from './assets/house/v9.jpg';
import House10 from './assets/house/v10.jpg';
import House11 from './assets/house/v11.jpg';
import bath1 from './assets/house/b1.jpg';
import bath2 from './assets/house/b2.jpg';
import bath3 from './assets/house/b3.jpg';
import bath4 from './assets/house/b4.jpg';

// import icons

import { MdBalcony } from "react-icons/md";
import {
  FaWifi,
  FaWind,
  FaCoffee,
  FaBath,
  FaParking,
  FaSwimmingPool,
  FaPaw,
  FaCouch,
  FaBaby,
  FaCheck,
  FaUtensils,
  FaWater,
  FaSun,
  FaShower,
  FaSeedling,
} from 'react-icons/fa';

export const roomData = [
  {
    id: 1,
    name: 'Peniche Rooftop Villa',
    description:
    <div>
     <p className='h3 mb-3 lg:mt-12 sm: mt-8 font-primary text-2xl'>House is divided by two floor.</p>
 <p className='font-secondary text-[18px]'>First floor</p>
 <ul className='flex flex-col gap-y-4 font-secondary'>
<li className="flex items-center"> <div><FaCheck className='sw-4 h-4 mr-2 text-straw' /></div> <p>Living room with AC system and TV</p></li>
<li className="flex items-center"> <div><FaCheck className='sw-4 h-4 mr-2 text-straw' /></div> <p>Kitchen  (Coffe machine, dishwasher, oven, cooking plate, cooking basics, dishes and cutlery, fridge and freezer, kettle, microwave, toaster, wine glasses)</p></li>
<li className="flex items-center"> <div><FaCheck className='sw-4 h-4 mr-2 text-straw' /></div> <p>Dining area (AC, Dining table with 10 seats, 2 baby chairs)</p></li>
<li className="flex items-center"> <div><FaCheck className='sw-4 h-4 mr-2 text-straw' /></div> <p>One bedroom with private bathroom (double bed, AC, bed linen, clothes storage,  extra pillows, blankets, hangers, room darkening blinds, shower cabin and hair dryer)</p></li>
<li className="flex items-center"> <div><FaCheck className='sw-4 h-4 mr-2 text-straw' /></div> <p>Guest bathroom</p></li>
</ul>
<p className='font-secondary text-[18px] mt-8'>Second floor</p>
<ul className='flex flex-col gap-y-4 font-secondary'>
<li className="flex items-center"> <div><FaCheck className='sw-4 h-4 mr-2 text-straw' /></div> <p>One bedroom with two bunk beds - 4 single adult beds (AC, bed linen, clothes storage,  extra pillows, blankets, hangers, room darkening blinds, dedicated work space)</p></li>
<li className="flex items-center"> <div><FaCheck className='sw-4 h-4 mr-2 text-straw' /></div> <p>One  bedroom with private bathroom and working desk (king bed, AC, bed linen, clothes storage,  extra pillows, blankets, hangers, room darkening blinds, couch, TV and dedicated working space, shower cabin and hair dryer) </p></li>
<li className="flex items-center"> <div><FaCheck className='sw-4 h-4 mr-2 text-straw' /></div> <p>One bedroom (AC, bed linen, clothes storage,  extra pillows, blankets, hangers, room darkening blinds, dedicated work space)</p></li>
<li className="flex items-center"> <div><FaCheck className='sw-4 h-4 mr-2 text-straw' /></div> <p>One bathroom (bathtub, hair dryer)</p></li>
</ul>
</div>,
    facilities: [
      { name: 'Wifi', icon: <FaWifi /> },
      { name: 'AC', icon: <FaWind /> },
      { name: 'Coffee', icon: <FaCoffee /> },
      { name: '4 bathrooms', icon: <FaBath /> },
      { name: 'Living room', icon: <FaCouch /> },
      { name: 'Outdoor shower', icon: <FaShower /> },
      { name: 'Swimming pool', icon: <FaSwimmingPool /> },
      { name: 'Parking space nearby', icon: <FaParking /> },
      { name: 'Ocean view', icon: <FaWater /> },
      { name: '2 balconies', icon: <MdBalcony /> },
      { name: 'Baby cot - on request', icon: <FaBaby /> },
      { name: 'Kitchen', icon:  <FaUtensils />  },
      { name: 'Garden', icon: <FaSeedling /> },
      { name: 'Rooftop', icon: <FaSun /> },
    ],
    //size: 150,
    beds: 7,
    maxPerson: 10,
    image: Room1Img,
    imageLg: Room1ImgLg,
    images: [House1, House2, House3, House4, House5, House6, House7, House8, House9, bath1, bath2, bath3, bath4, House10, House11]
  },
  // {
  //   id: 2,
  //   name: 'Studio',
  //   description:
  //     'Lorem ipsum dolor sit amet consectetur adipisicing elit.Ea placeat eos sed voluptas unde veniam eligendi a. Quaerat molestiae hic omnis temporibus quos consequuntur nam voluptatum ea accusamus, corrupti nostrum eum placeat quibusdam quis beatae quae labore earum architecto aliquid debitis.',
  //   facilities: [
  //     { name: 'Wifi', icon: <FaWifi /> },
  //     { name: 'Coffee', icon: <FaCoffee /> },
  //     { name: 'Private bathroom', icon: <FaBath /> },
  //     { name: 'Outdoor shower', icon: <FaShower /> },
  //     { name: 'Swimming pool', icon: <FaSwimmingPool /> },
  //     { name: 'Parking space', icon: <FaParking /> },
  //     { name: 'Baby cot - on request', icon: <FaBaby /> },
  //     { name: 'Kitchen', icon: <FaUtensils /> },
  //     { name: 'Garden', icon: <FaSeedling /> },
  //   ],
  //   size: 50,
  //   maxPerson: 2,
  //   image: Room2Img,
  //   imageLg: Room2ImgLg,
  //   images: [Studio1, Studio2, Studio3]
  // },
  
];
export const activityData = [
  {
    id: 1,
    name: 'Yoga',
    description:
      'The open rooftop and poolside terrace can be utilized as spaces for yoga sessions at the villa. Enhance your stay by complementing your rooftop and poolside yoga sessions with classes at Alma Yoga Studio. Located nearby in Peniche, Alma Yoga Studio offers a regular schedule of invigorating practices led by experienced instructors, providing additional opportunities to deepen your yoga journey and connect with the local community.',
    image: yogaImg,
  },
  {
    id: 2,
    name: 'Swimming Pool',
    description:
      "Dive into pure relaxation at our villa's sparkling swimming pool. With a maximum depth of 1.5 meters, our pool beckons both swimmers and sunbathers alike. While the water may not be warm, its cool embrace provides a refreshing escape from the sun's warmth.",
    image: poolImg,
  },
  {
    id: 3,
    name: 'Surfing',
    description:
       "Explore the exhilarating waves of Peniche, Portugal's renowned surfing paradise. Nestled along the stunning coastline, Peniche boasts some of the best surf spots in Europe. From the legendary Supertubos, to the longboard paradise in Baleal.",
    image: surfImg,
  },
  {
    id: 4,
    name: 'Hiking',
    description:
      "Discover Peniche's diverse hike trails, offering a range of scenic experiences. Explore coastal paths along Cabo Carvoeiro's cliffs for stunning ocean views or venture into Berlenga Island Nature Reserve for serene beaches and wildlife sightings. Whether you prefer easy walks or more challenging treks, Peniche's trails cater to all levels of adventurers, promising memorable outdoor experiences in Portugal's natural beauty.",
    image: hikeImg,
  },
  
  
];
export const locationData = [
  {
    id: 1,
    description:
    <p className='font-secondary text-justify lg:text-xl sm: text-l'>
    The property offers a serene and picturesque escape, surrounded by a <b className='font-semibold'>panoramic view of Peniche and the ocean beyond</b>.
    <br/>The property features a minimalist external arquitecture and design that perfectly belnds with a warm and inviting Scandinavian interior, offering a peaceful retreat and also conveniently located near a host of attractions. </p>,
    image: viewImg,
  },
  {
    id: 2,
    description:
    <p className='font-secondary lg:text-xl sm: text-l'>
    Peniche is renowned as a <b className='font-semibold'>world-class surf destination</b>, and the villa is conveniently situated close to the famous surf spots in the area. Whether you are an experienced surfer or a beginner eager to catch your first wave, the pristine beaches and consistent swells offer a playground for water sports enthusiasts.
    Located just 60 km way from Peniche, you'll discover the awe-inspiring <b className='font-semibold'>Nazaré</b>, home of one of the world's biggest waves.
    </p>,
    image: coastImg,
  },
  {
    id: 3,
    description:
    <p className='font-secondary lg:text-xl sm: text-l'>
    Just a short distance away lies the historic city of <b className='font-semibold'>Óbidos</b>, a gem with its medieval architecture, cobbled streets, and well-preserved city walls. Strolling through Óbidos feels like stepping back in time, and the vibrant cultural scene and charming local shops provide ample opportunities for exploration.
    </p>,
    image: villageImg,
  },
  {
    id: 4,
    description:
    <p className='font-secondary lg:text-xl sm: text-l'>
    For those with an appreciation for craftsmanship, nearby, in <b className='font-semibold'>Caldas city</b>, you can visit <b className='font-semibold'>ceramic shops</b> and bring home unique, handmade ceramics as souvenirs.
    Overall, the Peniche Rooftop is a haven of tranquility, offering stunning views, access to historical sites, and easy reach to famous surf spots. It presents an ideal blend of relaxation, cultural exploration, artistic inspiration, and thrilling adventures in the ocean.</p>,
    image: ceramicImg,
  },
]

export const priceData =[
  {
    id: 1,
    name: "Villa",
    description:
    <div className="bg-white font-tertiary shadow-lg rounded-lg p-6">
        <div className="flex items-center mb-6">
      <span className="text-xl font-semibold"> From 400 EUR</span>
      <span className="text-gray-500 ml-2 text-xl">/day - price for 8 guests</span>
    </div>
    <div className="flex items-center justify-between mb-4">
      <h3 className="text-xl "> capacity: <b className='font-semibold'>8 - 12 guests</b></h3>
      <span className="bg-green-500 text-white px-2 py-1 rounded-full text-sm">Popular</span>
    </div>
   <div className="flex items-center mb-6">
   <span className="text-xl font-semibold">50 EUR</span>
   <span className=" ml-2 text-xl">/day per person</span>
    </div>
    <ul className="space-y-4 text-[18px]">
    <li className="flex items-center">
    <div><FaCheck className='sw-4 h-4 mr-2 text-straw' /></div>
        <span>The minimum length of stay is two nights</span>
      </li>
      <li className="flex items-center">
        <div><FaCheck className='sw-4 h-4 mr-2 text-straw' /></div>
        <span>Children under 2 years old - free of charge. Baby cot available on request for 15 EUR/day</span>
      </li>
      <li className="flex items-center">
      <div><FaCheck className='sw-4 h-4 mr-2 text-straw' /></div>
        <span>Reservation fee ??% of the total fee  - non refundable</span>
      </li>
      <li className="flex items-center">
      <div><FaCheck className='sw-4 h-4 mr-2 text-straw' /></div>
        <span>Refundable deposit fee - ?? EUR </span>
      </li>
      <li className="flex items-center">
      <div><FaCheck className='sw-4 h-4 mr-2 text-straw' /></div>
        <span>Full payment on arrival day - before entering the house </span>
      </li>
    </ul>
  </div>,
  },
  {
    id: 2,
    name: "Studio",
    description:
    <div className="bg-white font-tertiary shadow-lg rounded-lg p-6">
    <div className="flex items-center mb-6">
      <span className="text-xl font-semibold">From 100 EUR</span>
      <span className="text-gray-500 ml-2 text-xl">/day - price for 2 guests</span>
    </div>
    <div className="flex items-center justify-between mb-4">
    <h3 className="text-xl "> capacity: <b className='font-semibold'>2 - ??? (up to how many?) guests</b></h3>

    </div>
    <div className="flex items-center mb-6">
      <span className="text-xl font-semibold">50 EUR</span>
      <span className=" ml-2 text-xl">/day per person</span>
    </div>
    <ul className="space-y-4 text-[18px]">
    <li className="flex items-center">
    <div><FaCheck className='sw-4 h-4 mr-2 text-straw' /></div>
        <span>The minimum length of stay is four nights </span>
      </li>
      <li className="flex items-center">
        <div><FaCheck className='sw-4 h-4 mr-2 text-straw' /></div>
        <span>Children under 2 years old - free of charge. Baby cot available on request for 15 EUR/day</span>
      </li>
      <li className="flex items-center">
      <div><FaCheck className='sw-4 h-4 mr-2 text-straw' /></div>
        <span>Reservation fee ??% of the total fee  - non refundable</span>
      </li>
      <li className="flex items-center">
      <div><FaCheck className='sw-4 h-4 mr-2 text-straw' /></div>
        <span>Refundable deposit fee - ?? EUR </span>
      </li>
      <li className="flex items-center">
      <div><FaCheck className='sw-4 h-4 mr-2 text-straw' /></div>
        <span>Full payment on arrival day - before entering the house </span>
      </li>
    </ul>
    
  </div>,
  },
]

