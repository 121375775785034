import React, { useContext } from 'react';
// room context
import { RoomContext } from '../context/RoomContext';
// icons
import { BsPlus } from 'react-icons/bs';
import { FaPlus } from 'react-icons/fa';
import { FaMinus } from 'react-icons/fa';

const AdultsDropdown = () => {
  const { adults, setAdults } = useContext(RoomContext);

  const handleIncrement = (e) => {
    setAdults(adults + 1);
    e.preventDefault()
  };

  const handleDecrement = (e) => {
    if (adults > 1) {
      setAdults(adults - 1);
    }
    e.preventDefault()
  };

  return (
    <div className="w-full h-12 lg:h-16 bg-white flex items-center justify-between">
      <button
        className="text-gray-500 hover:text-gray-700 focus:outline-none"
        onClick={handleDecrement}
      >
       <FaMinus className="w-3 h-3 ml-2 text-straw" />
      </button>
      <span className="font-secondary text-lg">{adults} {adults === 1 ? 'Adult' : 'Adults'}</span>
      <button
        className="text-gray-500 hover:text-gray-700 focus:outline-none"
        onClick={handleIncrement}
      >
        <FaPlus className="w-3 h-3 mr-2 text-straw" />
      </button>
    </div>
  );
};

export default AdultsDropdown;