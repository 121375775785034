import React, { useContext } from 'react';
import ScrollToTop from '../components/ScrollToTop';
import locationImg from  '../assets/location.jpg';
import { Helmet } from 'react-helmet';


import { RoomContext } from '../context/RoomContext';
import LocationSection from '../components/LocationSection';

const Location = ({location}) => {
  const { locations } = useContext(RoomContext);
  return (
    <>
     <Helmet>
        <title>Location - Peniche Rooftop Villa</title>
        <meta name="description" content="Location of Peniche Rooftop. House is located near surf spot Nazaré. Property is surrounded by a panoramic view of Peniche and the ocean beyond." />
      </Helmet>
    <ScrollToTop />
  <div className='bg-cover bg-center h-[560px] relative flex justify-center items-center' style={{backgroundImage: `url(${locationImg})`}}>
  <div className='absolute w-full h-full bg-black/70 '></div>
  
  <div className=' flex flex-col items-center gap-12 mt-36'>
  <h1 className='lg:text-6xl sm: text-5xl relative uppercase text-white text-center z-20 font-primary '> 
  Our Location
  </h1>
  </div>

  </div>
  
  
<div className='first-location-container  sm: px-4 py-12'>
<div className='font-tertiary uppercase lg:text-[20px] sm:text-[25px] text-center tracking-[6px]'>
       Seaside serenity
</div>
<p className='font-primary font-medium lg:text-3xl text-center sm: text-2xl mt-4'>
  Peniche Rooftop Villa is located in a charming village named <br/>Casais de Mestre Mendo near Peniche, Portugal. 
</p>
</div>
{locations.map((location) => (
        <LocationSection key={location.id} location={location} />
      ))}
  </>
  )
}

export default Location