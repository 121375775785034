import React from 'react';


const LocationSection = ({ location }) => {
  const { id, image, description } = location;
  const isEven = id % 2 === 0;
  

  return (
    <>
    <div className='flex flex-col items-center'>
      {isEven ? (
        <div id="even" className='text-justify shadow-lg xl:w-3/4 lg:px-12 sm: px-4 gap-12 flex lg:flex-row sm:flex sm: flex-col py-5 overflow-hidden '>
          <img
            className="hover:scale-110 transition-all duration-300 lg:w-1/2 sm:w-full object-cover"
            src={image}
          />
          <p className="font-secondary lg:text-xl sm:text-[23px]">{description}</p>
        </div>
      ) : (
        <div id="uneven" className='text-justify shadow-lg xl:w-3/4 lg:px-12 md:px-4 sm: px-4 flex lg:flex-row sm:flex sm: flex-col py-5 gap-12 overflow-hidden'>
          <p className="font-secondary  lg:text-xl sm:text-[23px]">
            {description}
          </p>
          <img
            className="hover:scale-110 transition-all duration-300 lg:w-1/2 sm:w-full object-scale-down"
            src={image}
          />
        </div>
      )}</div>
    </>
  );
};

export default LocationSection;
  