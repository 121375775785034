import React from 'react';
//import swiper react component
import {Swiper, SwiperSlide} from 'swiper/react';
//import swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
// import required modules
import { EffectFade, Autoplay } from 'swiper';
//images
import Img1 from '../assets/heroSlider/house.jpg';
import Img2 from '../assets/heroSlider/surf.jpg';
import Img3 from '../assets/heroSlider/view.jpg';

const slides = [
  {
    title: 'Ideal for family holiday',
    bg: Img1,
    btnText: 'See our villa'
  },
  {
    title: 'Ten mins drive to Worldclass surf spots',
    bg: Img2,
    btnText: 'See our villa'
  },
  {
    title: 'Perfect place for Yoga and Surf retreats',
    bg: Img3,
    btnText: 'See our villa'
  }
];

const HeroSlider = () => {
  return (
  <Swiper modules={[EffectFade, Autoplay]} 
  effect={'fade'} 
  loop={true}
  autoplay={{
    delay: 4000,
    disableOnInteraction: false
  }}
  className='heroSlider h-[600px] lg:h-[860px]'>
    {slides.map((slide, index) => {
      //descructure slide
      const { title, bg, btnText} = slide;
      return (
      <SwiperSlide 
      className='h-full relative flex justify-center items-center' 
      key={index}>
        <div className='z-20 text-white text-center'>
          {/* <div className='uppercase font-tertiary tracking-[6px] mb-5 mt-36'>
            Just enjoy and relax
          </div> */}
          <h1 className='text-[32px] font-primary uppercase tracking-[2px] max-w-[920px] lg:text-[68px] leading-tight mb-6'>
            {title}
          </h1>
          <a href='#rooms'>
            <button className='font-tertiary uppercase bg-straw hover:bg-straw-800 text-primary text-sm py-2 px-4 mx-auto block max-w-[240px] mt-8 tracking-[4px]'>{btnText}
          </button>
          </a>
        </div>
        <div className='absolute top-0 w-full h-full'>
          <img className='object-cover h-full w-full' 
          src={bg} 
          alt=''
          />
        </div>
        {/* overlay */}
        <div className='absolute w-full h-full bg-black/70'></div>
      </SwiperSlide>
      );
    })}
  </Swiper>
  );
};

export default HeroSlider;
