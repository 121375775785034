import React, { useContext } from 'react';
//context
import { RoomContext } from '../context/RoomContext';
//components
import Activity from '../components/Activity';

import ScrollToTop from '../components/ScrollToTop';
import img from   '../assets/activities.jpg';
import { Helmet } from 'react-helmet';

const Activities = ({activity}) => {
  const { activities } = useContext(RoomContext);
  

  return (
    <>
     <Helmet>
        <title>Activities - Peniche Rooftop</title>
        <meta name="description" content="Activities in Peniche - Portugal. Accomodation nearby surf destination." />
      </Helmet>
    <ScrollToTop />
  <div className='bg-cover bg-center h-[560px] relative flex justify-center items-center' style={{backgroundImage: `url(${img})`}}>
  <div className='absolute w-full h-full bg-black/70 '></div>
  <div className='flex flex-col items-center gap-12 mt-36'>
  <h1 className='lg:text-6xl sm: text-5xl relative uppercase text-white text-center z-20 font-primary '> 
  Discover, Engage, Enjoy
  </h1>
  </div>
  </div>
  
  <section id="activities" className='py-12'>
  <div className='container mx-auto lg:px-0 '>
    <div className='text-center'>
    <div className='font-tertiary uppercase lg:text-[20px] sm:text-[25px] tracking-[6px]'>
       What to do during your stay?
      </div>
      <h2 className='font-primary uppercase text-[45px] mb-4'>
        Active recreation
      </h2>
    </div>
  
    {/* {grid} */}
    <div className='grid grid-cols-1 max-w-sm mx-auto gap-[30px] lg:grid-cols-2 lg:max-w-none lg:mx-0'>
      {activities.map((activity) => {
        return <Activity activity={activity} key={activity.id} />;
      })}
    </div>

  </div>
  </section>
  </>
  );
};

export default Activities;
