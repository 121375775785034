import React from 'react';
//link
import {Link} from 'react-router-dom';
//icons
import {BsArrowsFullscreen, BsPeople} from 'react-icons/bs';
import {FaBed} from 'react-icons/fa';


const Room = ({ room }) => {
  //destructure room
  const {id, name, image, beds, maxPerson, description, price } = room;
  return (
   <div className='bg-white shadow-2xl  group'>
    {/* {img} */}
    <div className='overflow-hidden '>
      <img 
      className='group-hover:scale-110 transition-all duration-300 w-full h-full object-cover' 
      src={image} 
      alt='' 
      />
    </div>
    
    {/* details */}
    <div className='bg-white shadow-lg max-w-[300px] mx-auto h-[60px] -translate-y-1/2 flex justify-center items-center uppercase font-tertiary tracking-[1px] font-semibold text-base'>
      <div className='flex justify-between w-[80%]'>
        {/* size */}
        <div className='flex items-center gap-x-2'>
          <div className='text-accent'>
          <FaBed />
          </div>
          <div className='flex gap-x-1'>
            <div>Beds: </div>
            <div>{beds}</div>
          </div>
        </div>
        {/* room capacity */}
        <div className='flex items-center gap-x-2'>
          <div className='text-accent'>
            <BsPeople className='text-[18px]'/>
          </div>
          <div className='flex gap-x-1'>
            <div>Max People: </div>
            <div>{maxPerson}</div>
          </div>
        </div>
      </div>
    </div>
    {/* name & description*/}
    <div className='text-center'>
      <Link to={`/room/${id}`}>
        <h3 className='h3 font-primary text-2xl'>{name}</h3>
      </Link>
      <p className='max-w-[300px] mx-auto lg:mb-6 font-secondary'>
      Spacious villa with a fully equipped kitchen, comfortable living room, four bathrooms, four bedrooms, swimming pool, and expansive balconies overlooking the ocean.
      </p>
    </div>
    {/* btn */}
    
<Link to={`/room/${id}`}><button className='font-tertiary  uppercase bg-black hover:bg-straw-800 text-primary text-sm py-2 px-4 mx-auto block max-w-[240px] mt-8 mb-8 tracking-[2px]'>View details </button></Link>
    </div>
  );
};

export default Room;
