import React, { useState, useContext } from 'react';
import { RoomContext } from '../context/RoomContext';
// datepicker
import DatePicker from 'react-datepicker';
// datepicker css
import 'react-datepicker/dist/react-datepicker.css';
import '../datepicker.css';
// icons
import { BsCalendar } from 'react-icons/bs';

const CheckIn = () => {
  const { startDate, setStartDate, setMinDate } = useContext(RoomContext);
  

  const handleChange = (date) => {
   
      setStartDate(date);
      setMinDate(date);
  
    }
  

  return (
    <div className='relative flex items-center justify-end h-12 lg:h-16'>
      <div className='absolute z-10 pr-8'>
        <div>
          <BsCalendar className='text-accent text-base' />
        </div>
      </div>
      <DatePicker
        className='font-secondary w-full h-full'
        placeholderText='Check in'
        selected={startDate}
        onChange={handleChange}
        selectsStart
        startDate={startDate}
        minDate={new Date()}
      />
    </div>
  );
};

export default CheckIn;

