
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
//components
import Header from './components/Header';
import Footer from './components/Footer';
import Rooms from './components/Rooms';


//pages
import Home from './pages/Home';
import RoomDetails from './pages/RoomDetails';
import Location from './pages/Location';
//react router
// import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Activities from './pages/Activities';


const App = () => {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/apartment" element={<Rooms />} />
        <Route path="/room/:id" element={<RoomDetails/>} />
        <Route path="/location" element={<Location />} />
        <Route path="/activities" element={<Activities />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;