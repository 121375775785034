import React, { useContext } from 'react';
//context
import { RoomContext } from '../context/RoomContext';
//components
import Room from '../components/Room';
//loader
import {SpinnerDotted} from 'spinners-react';

const Rooms = ({room}) => {
  const { rooms, loading } = useContext(RoomContext);
  

  return (
  <section id="rooms" className='py-12'>
    {/* overlay & spinner */}
    {loading && (
      <div className='h-screen fixed bottom-0 top-0 bg-black w-full z-50 flex justify-center'>
        <SpinnerDotted color='white'/>
      </div>
    )}
  <div className='container mx-auto lg:px-0 '>
    <div className='text-center'>
    {/* <div className='font-tertiary uppercase lg:text-[20px] sm:text-[25px] tracking-[6px] mb-4'>
       Peniche Rooftop Villa
      </div> */}
      {/* <h2 className='font-primary uppercase text-[45px] mb-4'>
        Divisions
      </h2> */}
    </div>
  
    {/* {grid} */}
    <div className='grid grid-cols-1 max-w-sm mx-auto gap-[30px] sm: max-w-none md:max-w-none lg:grid-cols-1 lg:max-w-none lg:mx-0'>
      {rooms.map((room) => {
        return <Room room={room} key={room.id} />;
      })}
    </div>

  </div>
  </section>
  );
};

export default Rooms;
