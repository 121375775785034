import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';
import LOGO from '../assets/LOGO.png';
import LOGO_LIGHT from '../assets/LOGO_LIGHT.png';
import { Link } from 'react-router-dom';
import { FaBars } from "react-icons/fa";
import { MdOutlineClose } from "react-icons/md";
import scrollToTop from '../components/ScrollToTop';


const Header = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [header, setHeader] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      window.scrollY > 50 ? setHeader(true) : setHeader(false);
    });
  }, []);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const scrollToApartments = () => {
    scroll.scrollTo(700);
  };
  const scrollToHome = () => {
    scroll.scrollTo(0);
  };
  
  const scrollToContact = () => {
    scroll.scrollTo(1800);
  }
  const scrollToBooking = () => {
    scroll.scrollTo(1800);
  }

  return (
    <header
      className={`${
        header ? 'bg-white shadow-lg text-secondary ' : ' text-white bg-transparent py-8 '
      } fixed z-50 w-full transition-all duration-500`}
    >
       
      <div className='container mx-auto flex flex-col items-center gap-y-6 lg:flex-row lg:justify-between lg:gap-y-0'>
      <div className=' relative flex justify-around gap-44 mr-8 md:gap-96'>
        <Link to=''
         onClick={() => {
          setShowMenu(false);
          scrollToTop();
        }}>
          {header ? (
            <img className='w-[150px] mb-4 mt-4' src={LOGO} alt='Logo' />
          ) : (
            <img className='w-[150px]' src={LOGO_LIGHT} alt='Logo' />
          )}
        </Link>
        <button 
          className='lg:hidden'
          onClick={toggleMenu}
          aria-label='Toggle Menu'
        >
       
        {showMenu ? (
          <FaBars className=' hidden' />
        ) : (
          <FaBars className='text-[24px] text-secondary' />
        )}
          
        </button>
        </div>
        <nav
          className={`${
            showMenu ? 'flex flex-col bg-white items-center text-secondary' : 'hidden'
          } font-tertiary tracking-[3px] text-[20px] flex  gap-y-6 uppercase lg:flex lg:flex-row lg:gap-x-10`}
          
        >
          <NavLink
            exact
            to='/'
            className='hover:text-accent transition'
            onClick={() => {
              setShowMenu(false);
              scrollToTop();
            }}
          >
            Home
          </NavLink>
          <Link
            to='/'
            onClick={() => {
              setShowMenu(false);
              scrollToApartments();
            }}
            style={{ cursor: 'pointer' }}
            className='hover:text-accent transition'
          >
            Villa
          </Link>

          <Link
            to='/activities'
            className='hover:text-accent transition ml-3 mr-3'
            onClick={() => setShowMenu(false)}
          >
            During your stay
          </Link>

          <Link
            to='/room/1'
            onClick={() => {
              setShowMenu(false);
              scrollToBooking();
            }}
            style={{ cursor: 'pointer' }}
            className='hover:text-accent transition'
          >
            Booking
          </Link>

          <Link
            to='/location'
            className='hover:text-accent transition'
            onClick={() => setShowMenu(false)}
          >
            Location
          </Link>
          <Link
            to='/'
            onClick={() => {
              setShowMenu(false);
              scrollToContact();
            }}
            style={{ cursor: 'pointer' }}
            className='hover:text-accent transition'
          >
            Contact
          </Link>
          <button 
          className='lg:hidden'
          onClick={toggleMenu}
          aria-label='Toggle Menu'
        >
       
        {showMenu ? (
          <MdOutlineClose className='text-[24px] text-secondary' />
        ) : (
          <FaBars className='hidden' />
        )}
          
        </button>
        </nav>
       
      </div>
    </header>
  );
};

export default Header;
