import React, {useState} from 'react';
//logo
import LOGO_LIGHT from '../assets/LOGO_LIGHT.png'
//icons
import { FaEnvelope, FaInstagram } from 'react-icons/fa';
import { FaMapMarkerAlt } from 'react-icons/fa';

const Footer = () => {
  const [copied, setCopied] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  
  const handleCopyEmail = () => {
    const emailText = 'penicherooftop@gmail.com';
    navigator.clipboard
      .writeText(emailText)
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 2000); 
      })
      .catch((error) => {
        console.error('Unable to copy email address:', error);
      });
  };
  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };


  return (
  <footer className='bg-gray-dark py-4 '>

    <div 
    className='sm: flex sm:justify-between md:flex md:justify-between  md:ml-[50px]'
    >
   <a href='/'>
      <img className='w-[200px]' src={LOGO_LIGHT} alt=''/>
    </a>
  
  <div className={`container mx-auto text-gray   ${
  // Medium screen size (>= 768px and < 1024px)
  "md:flex md:flex-col " +
  // Large screen size (>= 1024px)
  "lg:flex lg:flex-col " +
  // Extralarge screen size (>= 1024px)
  "xl:flex xl:justify-between xl:flex-row " +
  // Small screen size (< 768px)
  "sm:grid sm:grid-cols-1 sm:gap-x-4"
}`}>


    {/* <a href='tel:+47 93660561'>
    <div 
    id="phone"
    className= "flex flex-row gap-2 lg:mt-[40px] sm: mt-[20px] hover:text-straw"
    >
      <FaPhone className='mt-[2px] text-2xl ' /> +47 93660561 
      {showTooltip && (
              <span 
              className='absolute -top-8 left-0 text-xs bg-black text-white py-1 px-2 rounded opacity-100 transition-opacity duration-300'
              >
                Click to call
              </span>
            )}
    </div>
    </a> */}

    <div 
    id="e-mail"
    className= "flex flex-row gap-2 lg:mt-[40px] sm: mt-[20px] relative cursor-pointer hover:text-straw"
    >
    <FaEnvelope className='mt-[2px] text-2xl'/> 
    <span  onClick={handleCopyEmail}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}> penicherooftop@gmail.com
    </span>
     {showTooltip && (
              <span 
              className='absolute -top-8 left-0 text-xs bg-black text-white py-1 px-2 rounded opacity-100 transition-opacity duration-300'
              >
                Click to Copy
              </span>
            )}
            {copied && (
              <span className='absolute -top-8 left-0 text-xs bg-black text-white py-1 px-2 rounded opacity-100 transition-opacity duration-300'>
                Email Copied!
              </span>
            )}
    </div>

    <a href='https://www.instagram.com/penicherooftopvilla/' target="_blank">
    <div 
    id="instagram"
    className= "flex flex-row gap-2 lg:mt-[40px] sm: mt-[20px] hover:text-straw"
    >
    <FaInstagram className='mt-[1px] text-2xl'/> @penicherooftopvilla
    </div>
    </a>


    <div 
    id="location-pin"
    className='location-container'>
    <div 
    className= "flex flex-row gap-2 lg:mt-[40px] sm: mt-[20px]"
    >
    <FaMapMarkerAlt className='mt-[3px] text-2xl'/>
    Atouguia da Baleia <br/>Portugal
    </div>
    <iframe className='ml-4 mt-2' width="200" height="100" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=200&amp;height=200&amp;hl=en&amp;q=Casais%20de%20Mestre%20Mendo,%202525-222,%20Atouguia%20da%20Baleia,%20Portugal+(Peniche%20Rooftop)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.maps.ie/distance-area-calculator.html">measure acres/hectares on map</a></iframe>
    </div>
    </div>

    </div>

    <div 
    id="copyright"
    className='text-gray text-[13px]  mt-[50px] sm: ml-[20px]'
    >
    Copyright &copy; 2024. All rights reserved.
    </div>

  </footer>
  );
};

export default Footer;
