import React, { useState } from 'react';
import { FaCheck } from 'react-icons/fa';
import { MdOutlineClose } from "react-icons/md";
import Rooms from "../components/Rooms"


const AvailabilityModal = ({ roomName, dates, numOfPeople, kidsUnderTwo, onClose }) => {
  const [formData, setFormData] = useState({
    email: '',
    repeatedEmail: '',
  });
  const [emailMatch, setEmailMatch] = useState(true);
  const [showModal, setShowModal] = useState(true);
  const [isChecked, setIsChecked] = useState(false);
  const [showFullText, setShowFullText] = useState(false);

  const isDatePicked = dates !== '';

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    if (name === 'email') {
      if (value === formData.repeatedEmail && value !== '') {
        setEmailMatch(true);
      } else {
        setEmailMatch(false);
      }
    } else if (name === 'repeatedEmail') {
      if (value === formData.email && value !== '') {
        setEmailMatch(true);
      } else {
        setEmailMatch(false);
      }
    }
  };
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  const toggleFullText = () => {
    setShowFullText(!showFullText);
  };


  const handleSubmit = (e) => {
    e.preventDefault();
   
    setFormData({
   
      email: '',
      repeatedEmail: '',
      
    });
    
  };
 
  const formatDate = (date) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return date.toLocaleDateString(undefined, options);
  };
  const hideModal = () => {
    onClose()
  }

  const [startDate, endDate] = dates.split(' - ');

  const formattedStartDate = formatDate(new Date(startDate));
  const formattedEndDate = formatDate(new Date(endDate));

  const isValidDate = (date) => {
    return date instanceof Date && !isNaN(date);
  };
  
  return (
    <div>
    {showModal && (
      
   
    <div className="fixed font-secondary top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-4  shadow-lg w-full sm:w-[500px] md:w-[600px] lg:w-[700px]" style={{ zIndex: 999 }}>
       
      <h3 className="text-xl font-medium mb-2">Check Availability</h3>
      <button 
          className='absolute top-4 right-4'
          onClick={hideModal}
          aria-label='Close Menu'
        >
          <MdOutlineClose className='text-[24px] text-gray' />
          
        </button>
        
      <p name="informations">
        We will check the availability of the <b className='font-semibold'>{roomName}</b> for the given dates: <b className='font-semibold'>{formattedStartDate} - {formattedEndDate}</b> for <b className='font-semibold'>{numOfPeople}</b> (number of kids under 2 years old: {kidsUnderTwo}).
        <br />
        Please provide your email address below so we will contact you as soon as possible.
        <br/>If you have any additional remarks, please enter them in the "Message" input.
      </p>

      {(!isValidDate(new Date(startDate)) || !isValidDate(new Date(endDate))) && (
          <p className="text-red text-sm"> <br></br>Select a valid date range.</p>
        )}
  
     <form action="https://formsubmit.co/penicherooftop@gmail.com" method="POST"  className="mt-4">

      <input type="hidden" name="_subject" value={`Availability check request - ${roomName}`}/> 
      <input type="hidden" name="_cc" value="franciscomacedo84@gmail.com"></input>
      <input type="hidden" name="_template" value="table"></input>
     
      <input 
      type="hidden" 
      name="_autoresponse" 
      value="Hi! 
             Your availability check request has been sent succesfully.
             We will reply to you soon. 
             Best Regards, Peniche Rooftop. "
        />
      <input
      type="hidden"
      name="informations"
      value= {`Request to check the availability of the ${roomName} for the given dates: ${formattedStartDate} - ${formattedEndDate} for ${numOfPeople} (number of kids under 2 years old: ${kidsUnderTwo}).`}/>

      <input
          type="text"
          name="name"
          placeholder="Name"
          className="w-72 px-2 py-1 border border-gray-light rounded mt-4"
          required
        />
       
      <input
          type="email"
          name="email"
          placeholder="E-mail"
          value={formData.email}
          onChange={handleInputChange}
          className="w-72 px-2 py-1 border border-gray-light rounded  sm: mt-4 lg:ml-3"
          required
        />
        <div className='flex flex-row'>
      <input
          type="email"
          name="repeatedEmail"
          placeholder="Repeat your e-mail"
          value={formData.repeatedEmail}
          onChange={handleInputChange}
          className={`w-72 px-2 py-1 border border-gray-light ${
            emailMatch ? 'border gray-light'  : 'border-red'
          } rounded mt-4`}
          required
        />
         {emailMatch && formData.repeatedEmail !== '' && (
    <FaCheck className="text-green mt-8 ml-2" />
  )}
        </div>
         {!emailMatch && (
          <p className="text-red text-sm">The repeated email is not the same as above.</p>
        )}
        <textarea
          name="message"
          type="text"
          value={formData.remarks}
          placeholder="Message"
          className="w-full resize-y h-[80px] px-2 py-1 border border-gray-light rounded mt-4"
          rows="1"
        />

       <div className=' mt-2 lg:ml-10 lg:mr-8  sm: ml-3 sm: mr-2'>
        
        <input 
        className='mr-1'
        type="checkbox"
        checked={isChecked}
        onChange={handleCheckboxChange}
        required
        />
        <label className='text-[12px]'>
          {showFullText ? (
          <>
            The administrator of provided personal data is Peniche Rooftop. By submitting this form, I consent to the processing of my personal data for the purpose of responding to my inquiry. I am aware that my data will be protected in accordance with applicable laws on the protection of personal data.
          </>
        ) : (
          <>
            The administrator of provided personal data is Peniche Rooftop. By submitting this form, I consent to the processing of my personal data for the purpose of responding to my inquiry.{' '}
            <button
              className="text-blue-500 underline"
              onClick={toggleFullText}
            >
              Show more
            </button>
          </>
        )}
       </label>
       </div>

        <button
            type='submit'
            className='mt-2 bg-gray hover:bg-gray-dark text-white font-bold py-2 px-4 rounded mb-4'
           disabled={!emailMatch || !isValidDate(new Date(startDate)) || !isValidDate(new Date(endDate))}   
          >
          Submit
        </button>
      </form>
    </div> )}
  </div>
  );
};



export default AvailabilityModal;