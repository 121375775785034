import React, {useContext, useState} from 'react';
import {useParams} from 'react-router-dom';
import { RoomContext } from '../context/RoomContext';
import { FaTimes } from 'react-icons/fa';
import Gallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';



const RoomGallery = () => {
  const { rooms } = useContext(RoomContext);
  const {id} = useParams();

  const room = rooms.find(room => {
    return room.id === Number(id);
  });
  if (!room || !room.images || room.images.length === 0) {
    return <div>No room images available</div>; 
  }

  const images = room.images.map(image => ({
    original: image
  }));


  return (
    <div>
      
      <Gallery items={images} />
    </div>
  );
};

export default RoomGallery;
