import React, {createContext, useEffect, useState} from 'react';
import CheckIn from '../components/CheckIn';
import CheckOut from '../components/CheckOut';
//data
import {roomData} from '../data';
import {activityData} from '../data';
import {locationData} from '../data';
import {priceData} from '../data';
//create context
export const RoomContext = createContext({ numKidsUnder2: 0,
  setNumKidsUnder2: () => {},
});

const RoomProvider = ({children}) => {
  const [rooms, setRooms] = useState(roomData);
  const [images, setImages ] = useState(roomData);
  const [adults, setAdults] = useState(1);
  const [kids, setKids] = useState(0);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(null); 
  const [endDate, setEndDate] = useState(null); 
  const [minDate, setMinDate] = useState(new Date()); 
  const [numKidsUnder2, setNumKidsUnder2] = useState(0);
  const [activities, setActivities] = useState(activityData);
  const [locations, setLocations] = useState(locationData);
  const [prices, setPrices] = useState(priceData);

  useEffect(() => {
    setTotal(Number(adults[0]) + Number(kids[0]))
  });
  
  const updateBookedDates = (roomId, newBookedDates) => {
    setRooms(prevRooms => {
      return prevRooms.map(room => {
        if (room.id === roomId) {
          return { ...room, bookedDates: newBookedDates };
        }
        return room;
      });
    });
  };


  const handleClick = (e)=> {
    e.preventDefault();
    setLoading(true);
    
    //filter rooms based on total (person)
    const newRooms = roomData.filter( room => {
      return total <= room.maxPerson;
    });
    setTimeout(() => {
       setRooms(newRooms);
       setLoading(false);
    }, 1000); 
   
  };
  

  return (
  <RoomContext.Provider value={{ rooms, images, adults, setAdults, kids, setKids, handleClick, loading, updateBookedDates, startDate, setStartDate, minDate, setMinDate, endDate, setEndDate, numKidsUnder2, setNumKidsUnder2, activities, locations, prices}}>
    
    {children}
    </RoomContext.Provider>
  );
};


export default RoomProvider;
